var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"482f5d542b5ad584e0065f028041dadcf50dbaed"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
    init,
    replayIntegration,
    BrowserProfilingIntegration,
    captureConsoleIntegration,
} from "@sentry/nextjs";
import Bowser from "bowser";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
    dsn:
        SENTRY_DSN ||
        "https://209c76fb12b04b17b68ce83562cf0e8b@o503779.ingest.sentry.io/6363173",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    beforeSend: (event, hint) => {
        let isRestrictedError = false;

        try {
            const browser = Bowser.getParser(window.navigator.userAgent);
            const currentBrowser = browser.getBrowser();

            if (currentBrowser?.name?.toLowerCase()?.includes("samsung")) {
                return;
            }

            if (
                window.location.hostname === "localhost" ||
                window.location.hostname === "127.0.0.1"
            ) {
                return;
            }
        } catch (error) {
            console.log(error);
        }

        const restrictedErrors = [
            "Failed to set the 'buffer' property on 'AudioBufferSourceNode': Cannot set buffer to non-null after it has been already been set to a non-null buffer",
            "Cannot set properties of null (setting 'isLoaded')",
            "Cannot read properties of null (reading 'context')",
            "sound.url or sound.source must be set",
            "The buffer was already set",
            "Cannot read property 'context' of null",
            "NotSupportedError: The element has no supported sources.",
            "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission",
            "correctStatus.map is not a function",
            "Cannot read properties of null (reading 'style')",
            "NotSupportedError: Failed to load because no supported source was found.",
            "NotSupportedError: screen.orientation.lock() is not available on this device.",
            "AbortError: The operation was aborted.",
            "AbortError: A call to screen.orientation.lock() or screen.orientation.unlock() canceled this call.",
            "AbortError: The play() request was interrupted because video-only background media was paused to save power. https://goo.gl/LdLk22",
            "AbortError: The play() request was interrupted because video-only background media was paused to save power.",
            "AbortError: The operation was aborted.",
            "AbortError: The user aborted a request.",
            "Invalid regular expression: invalid group specifier name",
            "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
            "Cannot find propagation path to disconnected target",
            "NotAllowedError: Permission dismissed",
            "Cannot read properties of null (reading 'scale')",
            "SecurityError: The page needs to be fullscreen in order to call screen.orientation.lock().",
            "NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
            "Error: InvalidStateError: Cannot resume a context that has been closed",
            "InvalidStateError: Cannot resume a context that has been closed",
            "Error: InvalidStateError: Cannot resume a context that has been closed",
            "InvalidStateError: Cannot resume a context that has been closed",
            "ResizeObserver loop limit exceeded",
            "Error: Image failed to load customImage.tsx: https://cdn.homeworkapp.ai/sets-gamify-assets/dev/worksheet/1732829701784",
            "MEDIA_ELEMENT_ERROR: Format error",
            "Sound:PIPELINE_ERROR_READ: FFmpegDemuxer: data source error",
            "Failed to convert value to 'Response'",
            "FetchEvent.respondWith() Promise rejected",
            "NetworkError when attempting to fetch resource.",
            "Failed writing data to the file system",
            "UnknownError: Attempt to get records from database without an in-progress transaction",
            "IDBDatabase.transaction: Can't start a transaction on a closed database",
            "undefined is not an object (evaluating 'this.mediaController.media.webkitCurrentPlaybackTargetIsWireless')",
            "TypeError: undefined is not an object (evaluating 'media.duration')",
            "Cache.put() encountered a network error",
            "The fetching process for the media resource was aborted by the user agent at the user's request.",
            "[react-phone-number-input] Expected the initial `value` to be a E.164 phone number. Got +",
            "Amplitude Logger",
            "E.164 phone",
            "CleverTap",
        ];

        const networkErrors = [
            "Failed to fetch",
            "fetch failed",
            "Object captured as exception with keys: error, errorInfo, href",
            "Network Error",
            "AxiosError: Network Error",
            "Remote Config: Fetch client failed to connect to a network. Check Internet connection. Original error: Failed to fetch. (remoteconfig/fetch-client-network).",
            `Remote Config: The config fetch request timed out.  Configure timeout using "fetchTimeoutMillis" SDK setting. (remoteconfig/fetch-timeout).`,
            "timeout exceeded",
            "[object Object]",
            "Network Error",
            "Load failed",
            "Cannot read properties of undefined (reading 'data')",
            "Cannot read property 'getInitialProps' of undefined",
            "[Loader.load] Failed to load https://sets-gamify-assets.s3.ap-south-1.amazonaws.com/dev/home-explore/document/1699527489354.png. TypeError: Failed to fetch",
            "Route did not complete loading: /onboarding",
            "Loading initial props cancelled",
            "Request failed with status code 400",
            "AxiosError: Request failed with status code 400",
            `Abort fetching component for route: "/home/worksheet/[worksheet_id]"`,
            "A client-side exception has occurred, see here for more info: https://nextjs.org/docs/messages/client-side-exception-occurred",
            "Installations: Could not process request. Application offline. (installations/app-offline).",
            "Route did not complete loading: /home/worksheet/[worksheet_id]/[activity_id]",
            "Cannot read properties of undefined (reading 'avatarState')",
            "undefined is not an object (evaluating 'a.sent().data')",
            `NetworkError: AutoCancel`,
            `NetworkError: FetchEvent.respondWith() Promise rejected`,
        ];

        const iterable = [
            `Abort fetching component for route:`,
            `Route did not complete loading:`,
            `FirebaseError: Remote Config: Error thrown when opening storage.`,
            `sent().data`,
            `Failed to load static props`,
            `Failed to lookup route`,
            `The request is not allowed by the user agent or the platform in the current context`,
            `Request failed with status code`,
            `"undefined" is not valid JSON`,
            `Unexpected token '<'`,
            `Unexpected token <`,
            `Unexpected token ?`,
            `Request aborted`,
            `The play() request was interrupted by a call to pause().`,
            `The play() request was interrupted by a new load request.`,
            `Cannot set property 'isLoaded' of null`,
            `Expected to find a valid target. targetId=T41`,
            `The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.`,
            `Fetch client could not parse response`,
            `Fetch client failed to connect to a network`,
            `Cannot read properties of undefined (reading 'other_generic_data')`,
            `Cannot set properties of undefined (setting 'global_context_variables')`,
            `The object can not be found here.`,
            `The play method is not allowed by the user agent`,
            `timeout of`,
            `NotAllowedError`,
            `NotSupportedError`,
            `not supported`,
            `Failed to execute 'decodeAudioData'`,
            `Failed to start the audio device`,
            `zones`,
            `missing query values`,
            `Unexpected token u in JSON at position 0`,
            `The media resource indicated by the src attribute or assigned media provider object was not suitable.`,
            `Decoding failed`,
            `setting 'loaded'`,
            `Failed to execute 'removeChild' on 'Node'`,
            `Could not start audio source`,
            "attempted to hard navigate to the same URL",
            "l.sent() is undefined",
            `JSON.stringify cannot serialize cyclic structures.`,
            `Converting circular structure to JSON`,
            `Failed to generate access token`,
            `Cannot read properties of undefined (reading ‘accessToken’)`,
            `Cannot convert undefined or null to object`,
            `reading 'accessToken'`,
            `A React component suspended while rendering`,
            `Failed to register a ServiceWorker`,
            `Converting circular structure to JSON`,
            `play() failed because the user didn't`,
            `QuotaExceededError`,
            `Cannot read properties of undefined (reading 'accessToken')`,
            `TypeError: Failed to execute 'addAll'`,
            `Response is not OK`,
            `Failed to load script`,
            `'addAll' on 'Cache'`,
            `AbortError`,
            `response_error`,
            `Function not found`,
            `A network error occurred`,
            `timeout exceeded`,
            `Failed to fetch`,
            `Load failed`,
            `ResizeObserver loop limit exceeded`,
            `'text/html' is not a valid JavaScript MIME type`,
            `Failed to execute 'setRequestHeader' on 'XMLHttpRequest': String contains non ISO-8859-1 code point`,
            `Remote Config: The config fetch request timed out while in an exponential backoff state`,
            `Remote Config: Error thrown when reading from storage. `,
            `Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing`,
            `Failed to read the 'localStorage' property from 'Window': Access is denied for this document`,
            `Database deleted by request of the user`,
            `Requested device not found`,
            `Cannot read properties of undefined (reading 'other')`,
            `Importing a module script failed.`,
            `expected expression, got '<'`,
            `Unexpected token .`,
            `The operation is insecure.`,
            `Request failed with status code 500`,
            `evaluating 'media.currentTime'`,
            `Can't find variable: logMutedMessage`,
            `Non-Error promise rejection captured with value: Object Not Found Matching Id:1, MethodName:update, ParamCount:4`,
            `The transaction was aborted`,
            `Request failed`,
            `InvalidStateError: The object is in an invalid state.`,
            `InvalidStateError: Cannot close a closed AudioContext`,
            `NetworkError when attempting to fetch resource`,
            `Browser does not support the vibrate API`,
            `AbortError: Importing a module script is canceled.`,
            `queueMicrotask is not defined`,
            `Object captured as exception with keys: [object has no keys]`,
            `InvalidStateError:`,
            `AbortError:`,
            `This browser doesn't support the API's required to use the Firebase SDK`,
            "Image failed to load",
            "Event rejected due to missing API key",
            "Expected the initial `value` to be a E.164 phone number. Got +",
            "Connection to Indexed Database server lost.",
            "No ScriptProcessor was registered with this name",
            "Failed to execute 'open' on 'CacheStorage'",
        ];

        const restrictedMessages = [
            "[object Object]",
            "A client-side exception has occurred, see here for more info:",
            "CleverTap",
        ];

        event?.exception?.values?.map((error) => {
            if (
                restrictedErrors.includes(error.value) ||
                networkErrors.includes(error.value) ||
                iterable.some((v) => error?.value?.includes(v))
            )
                isRestrictedError = true;
        });

        if (restrictedMessages.some((v) => event?.message?.includes(v)))
            isRestrictedError = true;

        if (isRestrictedError) return null;

        // Add condition to disable replays
        // Check if the event should disable replays
        if (event.extra && event.extra.disable_replay) {
            event.extra.replay = null; // Remove replay data
        }

        return event;
    },
    integrations: [
        replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                window.location.origin,
                "https://(?:[a-zA-Z0-9-]+.)*mathai.ai/",
                "https://(?:[a-zA-Z0-9-]+.)*homeworkapp.ai/",
                "https://test3-api.homeworkapp.ai/test/ts/v3/personalizedLearning/getNextStateV2",
                "https://test3-api.homeworkapp.ai/test/ts/v3/personalizedLearning/getOnboardingStories",
                "https://test3-api.homeworkapp.ai/test/ts/v3/personalizedLearning/user",
                "https://test2-api.homeworkapp.ai/test/ts/v3/personalizedLearning/getNextStateV2",
                "https://test2-api.homeworkapp.ai/test/ts/v3/personalizedLearning/getOnboardingStories",
                "https://test2-api.homeworkapp.ai/test/ts/v3/personalizedLearning/user",
                "https://api-js.mixpanel.com/track/",
                "https://api.amplitude.com",
                "https://qa.graphql.sets.hmwrk.app/v1/graphql",
                "https://graphql.us.mathai.ai/v1/graphql",
            ],
            beforeErrorSampling: (event) => {
                const ignoreReplay = [
                    "Function not found",
                    "question variable",
                    "derived variable",
                    "Amplitude Logger",
                    "MEDIA_ELEMENT_ERROR",
                    "EmptyRanges",
                    "AUDIO_RENDERER_ERROR",
                    "react-phone-number-input",
                    "Video failed VideoPart.tsx",
                    "`Event` (type=error)",
                    "Amplitude project API key is not defined",
                    "Jp.onUserLogin is not a function",
                ];
                let ignoreError = false;
                event?.exception?.values?.map((error) => {
                    if (ignoreReplay.some((v) => error?.value?.includes(v)))
                        ignoreError = true;
                });
                console.log("Sentry replay: beforeErrorSampling", {
                    ignoreError,
                    error: event?.exception?.values[0]?.value,
                });
                if (ignoreError) return false;
                return true;
            },
        }),
        new BrowserProfilingIntegration(),
        captureConsoleIntegration({ levels: ["error"] }),
    ],
    replaysSessionSampleRate:
        process.env.NEXT_PUBLIC_SENTRY_PROJECT === "mathai" ? 0.0 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate:
        process.env.NEXT_PUBLIC_SENTRY_PROJECT === "mathai" ? 1.0 : 0,
    profilesSampleRate:
        process.env.NEXT_PUBLIC_SENTRY_PROJECT === "mathai" ? 1.0 : 0,
});
